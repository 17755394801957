var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('div', {
    staticClass: "main-contents"
  }, [!_vm.$vuetify.breakpoint.mobile ? _c('slide-banner', {
    staticClass: "main-pc",
    attrs: {
      "code": "main-pc",
      "hide-pagination": ""
    }
  }) : _c('slide-banner', {
    staticClass: "main-mo",
    attrs: {
      "code": "main-mo",
      "hide-pagination": ""
    }
  }), _c('div', {
    staticClass: "visual fade"
  }, [_c('div', {
    staticClass: "visual-slide"
  }, [_c('div', [_c('div', {
    staticClass: "_v"
  }, [_c('div', {
    staticClass: "v-bg v1",
    attrs: {
      "data-aos": "slide-right",
      "data-aos-easing": "linear",
      "data-aos-duration": "6000"
    }
  }), _c('div', {
    staticClass: "centre"
  }, [_c('div', {
    staticClass: "inner"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "text-wrap"
  }, [_c('h3', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300",
      "data-aos-easing": "ease-in-out",
      "data-aos-duration": "870"
    }
  }, [_vm._v("쿠폰스토리")]), _c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "600",
      "data-aos-easing": "ease-in-out",
      "data-aos-duration": "1000"
    }
  }, [_vm._v("24시간 편리하고 안전한구매"), _c('br'), _vm._v("대량 매입의 표준 쿠폰스토리")])])])])])])])])]), _c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "slot": "gshop-section",
      "width": "100%",
      "min-height": "100%"
    },
    slot: "gshop-section"
  }, [_c('v-responsive', {
    staticClass: "main-section mx-auto",
    staticStyle: {
      "overflow": "visible"
    },
    attrs: {
      "width": "100%",
      "max-width": "1230"
    }
  }, [_c('div', {
    staticClass: "section main-giftcards"
  }, [_c('h4', {
    staticClass: "section-title",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('span', [_vm._v("핫딜상품")]), _vm._v(" 쿠폰스토리가 준비한 핫!딜! "), _c('div', {
    staticClass: "product-box-buttons"
  }, [_c('button', {
    staticClass: "prev",
    attrs: {
      "type": "button"
    }
  }), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button"
    }
  })])]), _c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.giftcards, function (giftcard) {
    var _giftcard$thumb, _giftcard$thumb2;
    return _c('div', {
      key: giftcard._id,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      attrs: {
        "color": "transparent",
        "elevation": "0",
        "to": `/gshop/giftcards/${giftcard._id}`
      }
    }, [_c('div', [_c('img', {
      attrs: {
        "src": ((_giftcard$thumb = giftcard.thumb) === null || _giftcard$thumb === void 0 ? void 0 : _giftcard$thumb.path) || ((_giftcard$thumb2 = giftcard.thumb) === null || _giftcard$thumb2 === void 0 ? void 0 : _giftcard$thumb2.src)
      }
    })]), _c('div', {
      staticClass: "mt-4 gift-title text-left",
      staticStyle: {
        "text-overflow": "ellipsis",
        "overflow": "hidden",
        "display": "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical"
      }
    }, [_vm._v(_vm._s(giftcard.name))]), _c('div', {
      staticClass: "mt-2 mb-8 gift-subtitle text-left primary--text",
      staticStyle: {
        "text-overflow": "ellipsis",
        "overflow": "hidden",
        "display": "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical"
      }
    }, [_vm._v("권종 선택구매")])])], 1);
  }), 0)])])])], 1), _c('section', {
    staticClass: "section2 section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('v-row', {
    staticClass: "main-board-wrap"
  }, [_c('v-col', {
    staticClass: "main-board-box",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "main-board-title"
  }, [_c('router-link', {
    attrs: {
      "to": "/center/notifications"
    }
  }, [_vm._v("공지사항")])], 1), _c('div', {
    staticClass: "main-board-line"
  }), _c('table', {
    staticClass: "bbs-main-table"
  }, [_c('tbody', _vm._l(_vm.notifications, function (notification) {
    return _c('tr', {
      key: notification._id
    }, [_c('th', {
      attrs: {
        "align": "left"
      }
    }, [_c('router-link', {
      attrs: {
        "to": `/center/notifications/${notification._id}`
      }
    }, [_vm._v(_vm._s(notification.subject))])], 1), _c('td', {
      attrs: {
        "align": "right"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(notification.createdAt).format("YYYY-MM-DD")) + " ")])]);
  }), 0)])]), _c('v-col', {
    staticClass: "main-board-box",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "main-board-title"
  }, [_c('router-link', {
    attrs: {
      "to": "/board/question"
    }
  }, [_vm._v("고객센터")])], 1), _c('div', {
    staticClass: "main-board-line"
  }), _c('div', {
    staticClass: "main-board-customer"
  }, [_c('div', {}, [_c('img', {
    staticClass: "main-board-customer-div-item",
    attrs: {
      "src": "/images/pinbox/main_dialog.png",
      "alt": ""
    }
  }), _c('h5', {
    staticClass: "main-board-customer-div-item"
  }, [_vm._v("고객센터")])]), _c('div', [_c('h5', {
    staticClass: "main-board-customer-div-item"
  }, [_vm._v("Tel")]), _c('h6', {
    staticClass: "main-board-customer-div-item"
  }, [_vm._v("1800-3712")])]), _c('div', [_c('h5', {
    staticClass: "main-board-customer-div-item"
  }, [_vm._v("E-mail")]), _c('p', {
    staticClass: "main-board-customer-div-item"
  }, [_vm._v("couponstory@daum.net")])]), _c('div', [_c('p', {
    staticClass: "main-board-customer-div-item"
  }, [_vm._v("운영시간 : 24시간 연중 무휴 ")])])])])], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }