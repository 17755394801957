<template>
    <default-template>
        <!--Main Section [S]-->
        <div class="main-contents">

            <slide-banner v-if="!$vuetify.breakpoint.mobile" class="main-pc" code="main-pc" hide-pagination />
            <slide-banner v-else class="main-mo" code="main-mo" hide-pagination />

            <div class="visual fade">
                <div class="visual-slide">
                    <div>
                        <div class="_v">
                            <div class="v-bg v1" 
                                data-aos="slide-right"
                                data-aos-easing="linear"
                                data-aos-duration="6000"></div>
                            <div class="centre">
                                <div class="inner">
                                    <div class="container">
                                        <div class="text-wrap">
                                            <h3
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                            data-aos-easing="ease-in-out"
                                            data-aos-duration="870">쿠폰스토리</h3>
                                            <p
                                            data-aos="fade-up"
                                            data-aos-delay="600"
                                            data-aos-easing="ease-in-out"
                                            data-aos-duration="1000">24시간 편리하고 안전한구매<br>대량 매입의 표준 쿠폰스토리</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--기존 구조 [S]-->
            <v-responsive slot="gshop-section" width="100%" min-height="100%" class="overflow-visible">

                <!-- <slide-banner v-if="!$vuetify.breakpoint.mobile" code="main-pc" /> -->

                <v-responsive width="100%" max-width="1230" class="main-section mx-auto" style="overflow:visible;">
                    
                    <div class="section main-giftcards">
                        <h4 slot="header" class="section-title">
                            <span>핫딜상품</span> 쿠폰스토리가 준비한 핫!딜!
                            <div class="product-box-buttons">
                                <button class="prev" type="button"></button>
                                <button class="next" type="button"></button>
                            </div>
                        </h4>
                        
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div v-for="giftcard in giftcards" :key="giftcard._id" class="swiper-slide">
                                    <v-card color="transparent" elevation="0" :to="`/gshop/giftcards/${giftcard._id}`" >
                                        <div>
                                            <img :src="giftcard.thumb?.path || giftcard.thumb?.src" />
                                        </div>
                                        <div class="mt-4 gift-title text-left" style="text-overflow: ellipsis; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">{{ giftcard.name }}</div>
                                        <div class="mt-2 mb-8 gift-subtitle text-left primary--text"  style="text-overflow: ellipsis; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;">권종 선택구매</div>
                                    </v-card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <main-giftcard :filter="{ 'category-code': 'book' }">
                        <span slot="header" class="font-weight-bold main-category--title">도서상품권</span>
                    </main-giftcard>

                    <main-giftcard :filter="{ 'category-code': 'cultureland' }" class="mt-8 mt-lg-16">
                        <span slot="header" class="font-weight-bold main-category--title">컬쳐랜드</span>
                    </main-giftcard>

                    <main-giftcard :filter="{ 'category-code': 'afreecatv' }" class="mt-8 mt-lg-16">
                        <span slot="header" class="font-weight-bold main-category--title">아프리카TV 별풍선</span>
                    </main-giftcard>

                    <main-giftcard :filter="{ 'category-code': 'google-giftcard' }" class="mt-8 mt-lg-16">
                        <span slot="header" class="font-weight-bold main-category--title">구글 기프트카드</span>
                    </main-giftcard> -->

                    <!-- <main-giftcard :filter="{ 'category-code': 'food' }" class="mt-8">
                        <span slot="header" class="headline font-weight-bold">외식·커피·케익 쿠폰</span>
                    </main-giftcard> -->

                    <!-- <main-giftcard :filter="{ 'category-code': 'life' }" class="mt-8">
                        <span slot="header" class="headline font-weight-bold">주유·편의점·생활 쿠폰</span>
                    </main-giftcard> -->

                </v-responsive>
            </v-responsive>
            <!--기존 구조 [E]-->

            <!--Section Board [S]-->
            <section class="section2 section">
                <div class="container">
                    <v-row class="main-board-wrap">
                        <v-col cols="12" md="6" class="main-board-box">
                            <h5 class="main-board-title"><router-link to="/center/notifications">공지사항</router-link></h5>
                            <div class="main-board-line"></div>
                            <table class="bbs-main-table">
                                <tbody>
                                    <tr v-for="notification in notifications" :key="notification._id">
                                        <!-- 공지사항 글 -->
                                        <th align="left">
                                            <router-link :to="`/center/notifications/${notification._id}`">{{ notification.subject }}</router-link>
                                        </th>
                                        <td align="right">
                                            {{ $dayjs(notification.createdAt).format("YYYY-MM-DD") }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-col>
                        <v-col cols="12" md="6" class="main-board-box">
                            <h5 class="main-board-title"><router-link to="/board/question">고객센터</router-link></h5>
                            <div class="main-board-line"></div>
                            <div class="main-board-customer">
                                <div class="">
                                    <img class="main-board-customer-div-item" src="/images/pinbox/main_dialog.png"
                                        alt="">
                                    <h5 class="main-board-customer-div-item">고객센터</h5>
                                </div>
                                <div>
                                    <h5 class="main-board-customer-div-item">Tel</h5>
                                    <h6 class="main-board-customer-div-item">1800-3712</h6>
                                </div>
                                <div>
                                    <h5 class="main-board-customer-div-item">E-mail</h5>
                                    <p class="main-board-customer-div-item">couponstory@daum.net</p>
                                </div>
                                <div>
                                    <p class="main-board-customer-div-item">운영시간 : 24시간 연중 무휴 </p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </section>
            <!--Section Board [E]-->
        </div>
        <!--Main Section [E]-->
    </default-template>
</template>
<script>
import api from "@/api";

import DefaultTemplate from "../DefaultTemplate.vue";
import GiftcardView from "@/components/client/gshop/giftcard/giftcard-view.vue";
import SlideBanner from "@/components/client/banner/slide-banner.vue";
import MainCategoryList from "@/components/client/main/main-category-list.vue";
import MainCategoryItem from "@/components/client/main/main-category-item.vue";
import MainGiftcard from "@/components/client/main/main-giftcard-list.vue";
import MainQuestionList from "@/components/client/main/main-question-list.vue";
import MainFaqList from "@/components/client/main/main-faq-list.vue";
import MainNotificationList from "@/components/client/main/main-notification-list.vue";

export default {
    components: {
        DefaultTemplate,
        SlideBanner,
        GiftcardView,
        MainCategoryList,
        MainCategoryItem,
        MainGiftcard,
        MainQuestionList,
        MainFaqList,
        MainNotificationList,
    },
    data(){
        return {
            giftcards: [],

            notifications: [],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { giftcards } = await api.v1.gshop.giftcards.gets({ headers: { limit: 0 }, params: { type: "giftcard" } });
            this.giftcards = giftcards;

            let { notifications } = await api.v1.center.notifications.gets({ headers: { limit: 5 } });
            this.notifications = notifications;

            window?.AOS?.init?.();

            this.$nextTick(() => {
                var partnerSlide = new window.Swiper('.main-section .swiper-container', {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween : 10,
                    speed: 500,
                    loop: true,
                    navigation: {
                        nextEl: '.product-box-buttons .next',
                        prevEl: '.product-box-buttons .prev'
                    },
                    breakpoints: {
                        
                        1024: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    },
                });
            });
        }
    }
}
</script>

<style scoped>
.v-application p { margin: 0; }

.centre { display: table; width: 100%; height: 100%; }
.centre .inner { display: table-cell; vertical-align: middle; width: 100%; height: 100%; }

.container { margin: auto; max-width: 1050px; }
.container-45 { margin: auto; max-width: 1095px; }

/* COMMON */
.section { padding: 70px 0px; }

/* Custom Style */
.section3 .formmail-table { background: transparent; }
.section-title { width: 100%; position: relative; margin: -10px 0 35px; text-align: center; font-size: 37px; font-weight: 200; }
.section-title span{ color: #db1d27; font-weight: bold;}
.section-title + p { margin-top: 15px; text-align: center; font-size: 17px; }
.main-board-title { margin-bottom: 10px; line-height: 30px; font-size: 28px; color: #db1d27;}
.main-board-title a { display: block; }

@media (max-width: 1024px){
    .section-title { line-height: 18px; font-size: 23px; }
    .section-title + p { line-height: 16px; font-size: 15px; }
    .main-board-title { font-size: 20px; background-size: auto 30px; }
}
@media (max-width: 768px){
    .section-title { line-height: 15px; font-size: 20px; }
    .main-board-title { font-size: 18px; background-size: auto 25px; }
}




/* Main Board */
/* .main-board-wrap:after { content: ''; display: block; clear: both; } */
.section2{background-color: #f5f5f5;}
.section2 div { max-width: 1200px; }
.bbs-main-table { width: 100%; }
.main-board-wrap{display: flex; flex-direction: row;justify-content: space-between;}
.main-board-box{width: 300px;}
.main-board-line{ width: 100%;height: 2px;background-color: #cdcdcd; margin-top: -4px;margin-bottom: 7px;}
.main-board-box .notice-title{line-height: 45px;font-size: 18px;font-weight: bold;color: #707070;}
.main-board-box .notice-date{display: none;}
.main-board-customer{display: flex; flex-wrap: wrap;}
.main-board-customer div{display: flex;width: 100%;}
.main-board-customer div:nth-child(1){margin-top: 12px;}
.main-board-customer div h5{font-size: 18px;color: #333333;padding-right: 14px;}
.main-board-customer div h6{font-size: 28px;color: #f06971;}
.main-board-customer div p{}
.main-board-customer div img{padding-right: 10px;}
.main-board-customer-div-item{align-self: center;}

.main-board-money{display: flex;flex-direction: column;width: 287px;}
.main-board-money-box1{display: flex; justify-content: space-between;}
.main-board-money-box1 div{display: flex;flex-direction: column;align-self: center;}
.main-board-money-box1 div p{font-size: 18px;color: #707070;font-weight: bold;}
.main-board-money-box2{display: flex;flex-direction: column;;margin-top: 10px;}
.main-board-money-box2 p{font-size: 18px;color: #777777;margin-left: auto;margin-bottom: 6px;}
.main-board-money-box2 img{width: 211px;margin-left: auto;}

/* Main */
.title { position: relative; text-align: center; line-height: 38px; font-size: 42px; font-weight: 200; }
.title + p { margin-top: 15px; text-align: center; font-size: 17px; }
.title-light { color: #fff !important; }
.title-light + p { color: rgba(255,255,255,.7) !important; }
.main-board-title { margin-bottom: 10px; line-height: 30px; font-size: 25px; }
.main-board-title a { display: block; background: url('/images/pinbox/main-board-title.png') no-repeat right 1px center; }

@media (max-width: 1024px){
    .title { line-height: 18px; font-size: 23px; }
    .title + p { line-height: 16px; font-size: 15px; }
    .main-board-title { font-size: 20px; background-size: auto 30px; }
}
@media (max-width: 768px){
    .title { line-height: 15px; font-size: 20px; }
    .main-board-title { font-size: 18px; background-size: auto 25px; }
    .section { padding: 70px 0; }
}


.product-box-buttons { display: flex; align-items: center; position: absolute; top: 0; right: 0; width: 100px; box-shadow: 0px 5px 10px 0px rgba(0,0,0,.2); }
.product-box-buttons .prev,
.product-box-buttons .next { width: 50px; height: 50px; background-color: #ffffff; background-repeat: no-repeat; background-position: center; }
.product-box-buttons .prev { background-image: url('/images/main/prev.png'); }
.product-box-buttons .next { background-image: url('/images/main/next.png'); }
.product-box-buttons .prev:hover { background-color: #db1d27; background-image: url('/images/main/prev-on.png'); }
.product-box-buttons .next:hover { background-color: #db1d27; background-image: url('/images/main/next-on.png'); }

.main-giftcards >>> .layout { justify-content: center; }
.main-giftcards >>> .v-card { display: block; padding: 0; border: 1px solid #d2d2d2 !important; border-radius: 15px; outline: none; overflow: hidden; }
.main-giftcards >>> .v-card:hover { border-color: #db1d27 !important; }
.main-giftcards >>> .v-card > div { border: none !important; }
.main-giftcards >>> .v-card .gift-title { display: block; padding: 0 10px; margin-top: 10px; font-size: 20px; font-weight: 500; }
.main-giftcards >>> .v-card .gift-subtitle { padding: 0 10px; }
.main-giftcards >>> .v-card__price { display: block; padding-bottom: 8px; line-height: 1; font-size: 22px; font-weight: 700; border-bottom: 1px solid #eeeeee; }
.main-giftcards >>> .v-card__price em { font-style: normal; }
.main-giftcards >>> .v-card__price small { font-size: 18px; color: #f55b5b; font-weight: 800; }
@media(max-width:1024px){
	.product-box-buttons { display: none; }
}
@media(max-width:768px){

}

</style>


<style scoped>
.v-ban-link { display: block; width: 100%; height: 100%; }
._v {
    height: 300px;
    position: relative;
}
._v .centre { position: relative; z-index: 2; }
.v-bg { position: absolute; top: 0; left: 0; width: 120%; height: 100%; z-index: 1;
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
}
._v.on .v-bg{
    -webkit-transition: linear 6s;
    -o-transition: linear 6s;
    transition: linear 6s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
._v.bg-on .v-bg{
    -webkit-transition: linear 6s;
    -o-transition: linear 6s;
    transition: linear 6s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.v1 { background: url('/images/couponstory/main_visual_1.png') no-repeat center / contain;}
.v2 { background: url('/images/couponstory/v2.jpg') no-repeat center / cover; }
.v3 { background: url('/images/couponstory/v3.jpg') no-repeat center / cover; }
.v4 { background: url('/images/couponstory/v4.jpg') no-repeat center / cover; }
.v5 { background: url('/images/couponstory/v5.jpg') no-repeat center / cover; }


._v h3{
    line-height: 52px;
    font-size: 42px;
    font-weight: bold;
    color: rgba(237,19,25,1);
    text-shadow: 0px 0px 10px rgba(0,0,0,.35);
}
._v p{
    margin-top: 12px;
    line-height: 32px;
    font-size: 22px;
    color: rgba(252,169,50,1);
    text-shadow: 0px 0px 10px rgba(0,0,0,.35);
}

@media (min-width:576px){
}
@media (min-width:768px){
    ._v {
        height: 480px;
        position: relative;
    }
    ._v h3{
        line-height: 72px;
        font-size: 50px;
    }
    ._v p{
        font-size: 24px;
        line-height: 34px;
    }
}
@media (min-width:1024px){
    ._v {
        height: 660px;
        position: relative;
    }
    ._v h3{
        line-height: 80px;
        font-size: 68px;
    }
    ._v p{
        font-size: 36px;
        margin-top: 20px;
        line-height: 48px;
    }
}
@media (min-width:1200px){
}
</style>

<style scoped>
[data-aos-duration='6000'] [data-aos],
  [data-aos][data-aos][data-aos-duration='6000'] {
    transition-duration: 6000ms;
  }
</style>